<template>
  <div>
    <b-modal id="modal-registro"
      size='xl'
      :title="titulo"
      @ok="agregarRegistro"
      @hidden="limpiarModal"
      hide-footer>
      <div class="row mb-2">
        <div class="col-sm-2 text-right">
          <div class="form-group">
            <label></label>
            <p class="col-form-label">
              Paciente:
            </p>
          </div>
        </div>
        <div class="col-sm-7">
          <div class="form-group">
            <label></label>
            <multiselect v-model="pacienteSelected"
              :options="pacientes"
              placeholder=""
              label="nombre" track-by="id"
              select-label=""
              deselect-label="X">
              <span slot="caret" v-if="!(pacienteSelected===null)"></span>
              <span slot="noResult">Sin Resultados</span>
            </multiselect>
          </div>
        </div>
        <div class="col-sm-3 pl-0">
          <div class="form-group">
            <label>Total Tratamiento:</label>
            <vue-numeric class="form-control"
              v-model="totalTratamiento"
              separator=","
              :precision="2"
              placeholder=""
            />
          </div>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-sm-2 text-right">
          <label class="col-form-label">
            Descripción:
          </label>
        </div>
        <div class="col-sm-10">
          <textarea
            v-model="descripcion"
            class="form-control"
            placeholder=""
            rows="3">
          </textarea>
        </div>
      </div>
      <div class="row" v-if="tesoreriasAUtilizar >= 1">
        <div class="col-sm-6 pr-0">
          <div class="form-group">
            <label>Caja-Banco:</label>
            <multiselect v-model="tesoreriaSelected1"
              :options="tesoreriasFiltered"
              placeholder=""
              label="nombre" track-by="id"
              select-label=""
              deselect-label="X">
              <span slot="caret" v-if="!(tesoreriaSelected1===null)"></span>
              <span slot="noResult">Sin Resultados</span>
            </multiselect>
          </div>
        </div>
        <div class="col-sm-3 py-1 px-1">
          <div class="form-group">
            <label></label>
            <vue-numeric class="form-control"
              v-model="monto1"
              separator=","
              :precision="2"
              placeholder=""
            />
          </div>
        </div>
        <div class="col-sm-1 py-0 pl-1">
          <div class="form-group" v-if="tesoreriasAUtilizar === 1">
            <label></label>
            <button class="btn btn-success"
             @click="aumentarTesoreria2">+</button>
          </div>
        </div>
      </div>
      <div class="row mb-2" v-if="tesoreriasAUtilizar >= 2">
        <div class="col-sm-6 pr-0">
          <multiselect v-model="tesoreriaSelected2"
            :options="tesoreriasFiltered"
            placeholder=""
            label="nombre" track-by="id"
            select-label=""
            deselect-label="X">
            <span slot="caret" v-if="!(tesoreriaSelected2===null)"></span>
            <span slot="noResult">Sin Resultados</span>
          </multiselect>
        </div>
        <div class="col-sm-3 py-1 px-1">
          <vue-numeric class="form-control"
            v-model="monto2"
            separator=","
            :precision="2"
            placeholder=""
          />
        </div>
        <div v-if="tesoreriasAUtilizar === 2"
          class="col-sm-1 pt-2">
          <i class="nav-icon i-Close-Window text-danger font-weight-bold align-middle"
            @click="quitarTesoreria2"
            style="cursor: pointer"></i>
        </div>
        <div v-if="tesoreriasAUtilizar === 2"
          class="col-sm-1 px-0">
          <button class="btn btn-success"
            @click="aumentarTesoreria3">+</button>
        </div>
      </div>
      <div class="row" v-if="tesoreriasAUtilizar === 3">
        <div class="col-sm-6 pr-0">
          <multiselect v-model="tesoreriaSelected3"
            :options="tesoreriasFiltered"
            placeholder=""
            label="nombre" track-by="id"
            select-label=""
            deselect-label="X">
            <span slot="caret" v-if="!(tesoreriaSelected3===null)"></span>
            <span slot="noResult">Sin Resultados</span>
          </multiselect>
        </div>
        <div class="col-sm-3 py-1 px-1">
          <vue-numeric class="form-control"
            v-model="monto3"
            separator=","
            :precision="2"
            placeholder=""
          />
        </div>
        <div class="col-sm-1 pt-2">
          <i class="nav-icon i-Close-Window text-danger font-weight-bold align-middle"
            @click="quitarTesoreria3"
            style="cursor: pointer"></i>
        </div>
      </div>
      <div class="row mt-5">
        <div class="col-sm-12 text-center">
          <button class="btn btn-success" @click="agregarRegistro">
            Guardar
          </button>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapFields } from 'vuex-map-fields';
import Multiselect from 'vue-multiselect';
import VueNumeric from 'vue-numeric';
import { mapActions } from 'vuex';
import { util } from '../../../plugins/util';

export default {
  name: 'ModalRegistro',
  components: {
    Multiselect,
    VueNumeric,
  },
  props: {
    editMode: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
    };
  },
  methods: {
    ...mapActions('anticipoAPIS', [
      'callIndexAPI',
      'callStoreAPI',
      'callUpdateApi',
    ]),
    aumentarTesoreria2() {
      this.tesoreriasAUtilizar = 2;
    },
    aumentarTesoreria3() {
      this.tesoreriasAUtilizar = 3;
    },
    quitarTesoreria2() {
      this.tesoreriasAUtilizar = 1;
      this.tesoreriaSelected2 = null;
      this.monto2 = 0;
    },
    quitarTesoreria3() {
      this.tesoreriasAUtilizar = 2;
      this.tesoreriaSelected3 = null;
      this.monto3 = 0;
    },
    limpiarModal() {
      this.tesoreriasAUtilizar = 1;
      this.pacienteSelected = null;
      this.descripcion = '';
      this.totalTratamiento = 0;
      this.tesoreriaID1 = 0;
      this.tesoreriaSelected1 = null;
      this.monto1 = 0;
      this.tesoreriaID2 = 0;
      this.tesoreriaSelected2 = null;
      this.monto2 = 0;
      this.tesoreriaID3 = 0;
      this.tesoreriaSelected3 = null;
      this.monto3 = 0;
      this.responseEdit = null;
    },
    async agregarRegistro() {
      if (this.tesoreriaSelected1 === null) {
        util.showNotify('Elije una Tesoreria', 'warn');
        return;
      }
      if (this.monto1 <= 0) {
        util.showNotify('El monto debe ser mayor a 0', 'warn');
        return;
      }
      if (this.tesoreriasAUtilizar >= 2 && this.tesoreriaSelected2 === null) {
        util.showNotify('Elije la Segunda Tesoreria', 'warn');
        return;
      }
      if (this.tesoreriasAUtilizar >= 2 && this.monto2 <= 0) {
        util.showNotify('El monto debe ser mayor a 0', 'warn');
        return;
      }
      if (this.tesoreriasAUtilizar === 3 && this.tesoreriaSelected3 === null) {
        util.showNotify('Elije la Tercera Tesoreria', 'warn');
        return;
      }
      if (this.tesoreriasAUtilizar === 3 && this.monto3 <= 0) {
        util.showNotify('El monto debe ser mayor a 0', 'warn');
        return;
      }
      this.$bvModal.hide('modal-registro');
      if (this.editMode) {
        await this.callUpdateApi();
      } else {
        await this.callStoreAPI();
      }
      this.callIndexAPI();
      this.limpiarModal();
    },
  },
  computed: {
    ...mapFields('modalRegistro', [
      'pacientes',
      'pacienteSelected',
      'descripcion',
      'totalTratamiento',
      'tesorerias',
      'tesoreriaSelected1',
      'monto1',
      'tesoreriaSelected2',
      'monto2',
      'tesoreriaSelected3',
      'monto3',
      'tesoreriasAUtilizar',
      'tesoreriaID1',
      'tesoreriaID2',
      'tesoreriaID3',
      'responseEdit',
    ]),
    tesoreriasFiltered() {
      if (this.tesoreriaSelected1 === null) {
        return this.tesorerias;
      }
      const COMBO_TEMPORAL1 = this.tesorerias.filter((el) => (
        el.id !== this.tesoreriaSelected1.id
      ));
      if (this.tesoreriaSelected2 === null) {
        return COMBO_TEMPORAL1;
      }
      const COMBO_TEMPORAL2 = COMBO_TEMPORAL1.filter((el) => (
        el.id !== this.tesoreriaSelected2.id
      ));
      if (this.tesoreriaSelected3 === null) {
        return COMBO_TEMPORAL2;
      }
      const COMBO_TEMPORAL3 = COMBO_TEMPORAL2.filter((el) => (
        el.id !== this.tesoreriaSelected3.id
      ));
      return COMBO_TEMPORAL3;
    },
    titulo() {
      if (this.editMode) {
        return 'Modificar Registro';
      }
      return 'Nuevo Registro';
    },
  },
};
</script>

<style scoped>
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
