<template>
  <div>
    <div class="screen-loading" v-if="showScreenLoader">
      <div class="text-center text-white custom-screen-center">
        <div class="align-middle spinner-bubble spinner-bubble-light mr-5"></div>
        <strong style="font-size:18px">Cargando...</strong>
      </div>
    </div>
    <div class="row mb-4">
      <div class="col-sm-12 mb-4">
        <TheModalRegistro :editMode="editMode"/>
        <div class="card text-left">
          <div class="card-header">
            <div class="row">
              <div class="col-sm-5">
                <div class="breadcrumb mb-2 custom-top-padding float-left">
                  <h1 class="mb-0"><strong>{{getTitleUI}}</strong></h1>
                </div>
                <button
                  class="btn th-custom-color px-4 rounded ml-3"
                  @click="abrirModalRegistro"
                  :disabled="!hasPermisionNuevoAnticipo"
                >
                  Nuevo
                </button>
              </div>
              <div class="col-sm-3 offset-sm-4">
                <div class="text-right">
                  <label class="switch switch-success mr-3">
                    <input
                      id="filter"
                      type="checkbox"
                      :checked="estado"
                      v-model="estado"
                      @change="listarAnticipos"
                    >
                    <span class="slider"></span>
                    <span>{{ labelActivos }}</span>
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="row mb-1">
              <div class="col-sm-3">
                <div class="btn-group">
                  <span class="pt-2 mr-1">Mostrar </span>
                  <b-form-select
                    v-model="por_pagina"
                    :options="pagina_opciones"
                    @change="listarAnticipos">
                  </b-form-select>
                  <span class="pt-2 ml-1"> Registros</span>
                </div>
              </div>
              <div class="col-sm-3 offset-sm-6 text-right">
                <div class="form-group row">
                  <div class="col-sm-4"></div>
                  <div class="col-sm-8">
                    <input type="text"
                      class="form-control"
                      v-model="busqueda"
                      autocomplete="off"
                      placeholder="Buscar"
                      @keyup="filtrarBusqueda"
                    >
                  </div>
                </div>
              </div>
            </div>
            <b-table class="table table-striped table-hover"
              :fields="getFieldsTable"
              :items="listaTabla"
              :busy="isLoading"
              :per-page="por_pagina"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc">
              <template #table-busy>
                <div class="text-center text-danger my-2">
                  <div class="align-middle spinner-bubble spinner-bubble-danger mr-5"></div>
                  <strong>Cargando...</strong>
                </div>
              </template>
              <template v-if="estado" #cell(editar)="items">
                <i
                  class="nav-icon i-Pen-5 font-weight-bold"
                  :class="hasPermisionModificarAnticipo? 'text-success':''"
                  @click="modificarRegistro(items.item)"
                  style="cursor: pointer"
                ></i>
              </template>
              <template #cell(ver)>
                <i
                  v-if="hasPermisionVerAnticipo"
                  class="nav-icon i-Eye font-weight-bold"
                  :class="hasPermisionVerAnticipo? 'text-warning':''"
                  style="cursor: pointer"
                ></i>
              </template>
              <template v-if="estado" #cell(imprimir)="items">
                <i
                  class="nav-icon i-File-Download font-weight-bold"
                  :class="hasPermisionImprimirAnticipo? 'text-info':''"
                  @click="onClickImprimirIcon(items.item)"
                  style="cursor: pointer"
                ></i>
              </template>
              <template v-if="estado" #cell(eliminar)="items">
                <i
                  class="nav-icon i-Close-Window font-weight-bold"
                  :class="hasPermisionEliminarAnticipo? 'text-danger':''"
                  @click="onClickEliminarIcon(items.item)"
                  style="cursor: pointer"
                ></i>
              </template>
              <template #cell(total)="data">
                {{data.item.total | numeral('0,0.00')}}
              </template>
              <template #cell(caja)="data">
                {{data.item.caja | numeral('0,0.00')}}
              </template>
              <template #cell(banco)="data">
                {{data.item.banco | numeral('0,0.00')}}
              </template>
              <template #cell(tarjeta)="data">
                {{data.item.tarjeta | numeral('0,0.00')}}
              </template>
            </b-table>
            <b-modal id="modal-opciones"
              :title="'Seleccione para Ver'"
              hide-footer>
              <div class="row mt-4">
                <div class="col-sm-6 text-center">
                  <button class="btn th-custom-color col-sm-10"
                   @click="downloadNota()">
                    NOTA
                  </button>
                </div>
              </div>
            </b-modal>
            <b-modal id="modal-eliminar"
              :title="'Seguro que quieres eliminar el Anticipo - N° '+selectedNro+'?'"
              hide-footer>
              <div class="row">
                <div class="col-sm-6">
                  <button class="col-sm-12 btn btn-danger" @click="eliminarRegistro">
                    Eliminar
                  </button>
                </div>
                <div class="col-sm-6">
                  <button class="col-sm-12 btn btn-outline-dark"
                  @click="$bvModal.hide('modal-eliminar')">Cancelar</button>
                </div>
              </div>
            </b-modal>
            <b-modal id="modal-info-eliminar"
              :title="'ACCION NO PERMITIDA'"
              hide-footer
            >
              <template #modal-title>
                <span class="text-danger">ACCION NO PERMITIDA!</span>
              </template>
              <div class="row">
                <div class="col-sm-11 pl-3">
                  <p>Existen instancias relacionadas. A continuación se detallan:</p>
                </div>
                <div class="col-sm-12">
                  <b-table class="table table-striped table-hover"
                    :fields="fieldsInfo"
                    :items="listaComprobantes"
                  >
                  </b-table>
                </div>
              </div>
            </b-modal>
            <!-- Paginacion -->
            <div class="row">
              <div class="col-sm-8"></div>
              <div class="col-sm-4">
                <b-pagination
                  v-model="pagina_actual"
                  :total-rows="totalFilas"
                  :per-page="por_pagina"
                  @input="listarAnticipos"
                  class="my-0 float-right"
                ></b-pagination>
              </div>
            </div>
            <!-- Fin Paginacion -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapFields } from 'vuex-map-fields';
import { mapActions, mapGetters } from 'vuex';
import axios from '@/modules/common/axiosERP';
import { util } from '@/plugins/util';
import { FUNCIONALIDADES } from '@/utils/globalConventions';
import TheModalRegistro from '../components/TheModalRegistro.vue';

export default {
  name: 'index',
  components: {
    TheModalRegistro,
  },
  data() {
    return {
      pagina_opciones: [50, 100],
      delayTimeout: null,
      fields: [
        {
          key: 'editar', label: ' ', thClass: 'th-custom-color', thStyle: { width: '3%' },
        },
        {
          key: 'ver', label: ' ', thClass: 'th-custom-color', thStyle: { width: '3%' },
        },
        {
          key: 'imprimir', label: ' ', thClass: 'th-custom-color', thStyle: { width: '3%' },
        },
        {
          key: 'eliminar', label: ' ', thClass: 'th-custom-color', thStyle: { width: '3%' },
        },
        {
          key: 'cuenta_por_pagar_numero', label: 'N°', thClass: 'th-custom-color', thStyle: { width: '5%' }, sortable: true,
        },
        {
          key: 'fecha', label: 'Fecha', thClass: 'th-custom-color', class: 'text-center', thStyle: { width: '7%' }, sortable: true,
        },
        {
          key: 'paciente', label: 'Paciente', thClass: 'th-custom-color', class: 'text-center', thStyle: { width: '17%' }, sortable: true,
        },
        {
          key: 'total', label: 'Total Tratamiento', thClass: 'th-custom-color', class: 'text-center', thStyle: { width: '14%' }, sortable: true,
        },
        {
          key: 'caja', label: 'Caja', thClass: 'th-custom-color', class: 'text-center', thStyle: { width: '10%' }, sortable: true,
        },
        {
          key: 'banco', label: 'Banco', thClass: 'th-custom-color', class: 'text-center', thStyle: { width: '10%' }, sortable: true,
        },
        {
          key: 'tarjeta', label: 'Tarjeta', thClass: 'th-custom-color', class: 'text-center', thStyle: { width: '10%' }, sortable: true,
        },
        {
          key: 'usuario', label: 'Usuario', thClass: 'th-custom-color', class: 'text-center', thStyle: { width: '14%' }, sortable: true,
        },
      ],
      sortBy: 'numero',
      sortDesc: true,
      selectedNro: null,
      selectedId: null,
      editMode: false,
      fieldsInfo: [
        {
          key: 'origen', label: 'Origen', class: 'text-center', thStyle: { width: '25%' },
        },
        {
          key: 'fecha', label: 'Fecha', class: 'text-center', thStyle: { width: '25%' },
        },
        {
          key: 'monto', label: 'Monto', class: 'text-center', thStyle: { width: '25%' },
        },
        {
          key: 'moneda', label: 'Moneda', class: 'text-center', thStyle: { width: '25%' },
        },
      ],
      showScreenLoader: false,
    };
  },
  created() {
    this.resetListarAnticipos();
    this.resetModalRegistro();
    this.listarAnticipos();
    if (this.hasPermisionNuevoAnticipo) {
      this.callCreateAPI();
    }
  },
  methods: {
    ...mapActions('anticipoAPIS', [
      'callIndexAPI',
      'callCreateAPI',
      'callEditApi',
      'callDestroyAPI',
    ]),
    ...mapActions('listarAnticipos', [
      'resetListarAnticipos',
    ]),
    ...mapActions('modalRegistro', [
      'resetModalRegistro',
    ]),
    listarAnticipos() {
      this.callIndexAPI();
    },
    abrirModalRegistro() {
      if (this.hasPermisionNuevoAnticipo) {
        this.editMode = false;
        this.$bvModal.show('modal-registro');
      }
    },
    filtrarBusqueda() {
      const delay = 400;
      clearTimeout(this.delayTimeout);
      this.delayTimeout = setTimeout(this.listarAnticipos, delay);
    },
    async modificarRegistro(fila) {
      if (this.hasPermisionModificarAnticipo) {
        this.listaComprobantes = [];
        this.editMode = true;
        this.showScreenLoader = true;
        await this.callEditApi(fila.id);
        this.showScreenLoader = false;
        if (this.listaComprobantes.length > 0) {
          this.$bvModal.show('modal-info-eliminar');
        } else {
          this.$bvModal.show('modal-registro');
        }
      }
    },
    setItems(fila) {
      this.selectedId = fila.id;
      this.selectedNro = fila.cuenta_por_pagar_numero;
    },
    async downloadNota() {
      try {
        const RESPONSE = await axios.get(`/clinic/advance/nota/${this.selectedId}`, {
          responseType: 'blob',
        });
        const BLOB = new Blob([RESPONSE.data], { type: 'application/pdf' });
        const DOWNLOAD_URL = URL.createObjectURL(BLOB);
        window.open(DOWNLOAD_URL, '_blank');
      } catch (error) {
        util.showNotify(error.response.data.message, 'error');
      }
    },
    async eliminarRegistro() {
      this.listaComprobantes = [];
      this.$bvModal.hide('modal-eliminar');
      await this.callDestroyAPI(this.selectedId);
      if (this.listaComprobantes.length > 0) {
        this.$bvModal.show('modal-info-eliminar');
      }
    },
    onClickImprimirIcon(item) {
      if (this.hasPermisionImprimirAnticipo) {
        this.setItems(item);
        this.$bvModal.show('modal-opciones');
      }
    },
    onClickEliminarIcon(item) {
      if (this.hasPermisionEliminarAnticipo) {
        this.setItems(item);
        this.$bvModal.show('modal-eliminar');
      }
    },
  },
  computed:
  {
    ...mapFields('listarAnticipos', [
      'isLoading',
      'estado',
      'busqueda',
      'pagina_actual',
      'por_pagina',
      'listaTabla',
      'totalFilas',
      'listaComprobantes',
    ]),
    ...mapGetters('main', [
      'getFuncionalidades',
      'hasPermisionNuevoAnticipo',
      'hasPermisionModificarAnticipo',
      'hasPermisionVerAnticipo',
      'hasPermisionEliminarAnticipo',
      'hasPermisionImprimirAnticipo',
    ]),
    labelActivos() {
      if (this.estado) {
        return 'Activos';
      }
      return 'Inactivos';
    },
    getFieldsTable() {
      let customFields = this.fields;
      if (this.hasPermisionVerAnticipo === false) {
        customFields = customFields.filter((obj) => (
          obj.key !== 'ver'
        ));
      }
      return customFields;
    },
    getTitleUI() {
      const ITEM = this.getFuncionalidades.find((el) => el.id === FUNCIONALIDADES.ANTICIPO);
      if (ITEM !== null) {
        return ITEM.descripcion;
      }
      return '';
    },
  },
};
</script>

<style scoped>
.screen-loading {
  width: 95.3%;
  height: 93.3%;
  background: rgba(0,0,0,0.5);
  position: absolute;
  z-index: 2;
}
.custom-screen-center {
  transform: translateY(35vh);
}
.custom-top-padding {
  padding-top: 5.53px;
}
</style>
